import SettingsContent from '../Wrapper';
import PublicDashboard from './PublicDashboard';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/public-dashboard'),
      component: SettingsContent,
      props: {
        headerTitle: 'Public Dashboard',
        icon: 'dashboard',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          name: 'publicdashboard_wrapper',
          redirect: 'view',
        },
        {
          path: 'view',
          name: 'public_dashboard_view',
          roles: ['administrator'],
          component: PublicDashboard,
        },
      ],
    },
  ],
};
