<template>
  <div class="dashboard-app--container">
    <loading-state
      v-if="iframeLoading"
      :message="$t('Carregando Integração...')"
      class="dashboard-app_loading-container"
    />
    <iframe
      v-if="mostrarIframe"
      :src="iframeUrl"
      @load="onIframeLoad"
    />
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import LoadingState from 'dashboard/components/widgets/LoadingState.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    LoadingState,
  },
  mixins: [alertMixin],
  data() {
    return {
      iframeLoading: true,
      mostrarIframe: false,
      iframeUrl: '',
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
      getAccount: 'accounts/getAccount',
    }),
  },
  mounted() {
    this.loadIframe();
  },
  methods: {
    onIframeLoad() {
      this.iframeLoading = false;
    },
    async loadIframe() {
      try {
        // Simulate an authentication process if necessary
        // const token = await this.authenticateUser();
        
        this.iframeUrl = "https://dashboards.ihtemconnect.com/public-dashboards/aad41b946c2a48539bfba9096a68ae44";
        this.mostrarIframe = true;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
iframe {
  width: 100%;
  height: 100%;
  border: 0;
}

.dashboard-app--container,
.dashboard-app--list,
.dashboard-app--list iframe {
  height: 100%;
  width: 100%;
}

.dashboard-app--list iframe {
  border: 0;
}
.dashboard-app_loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
</style>
